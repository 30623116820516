import styled from '@emotion/styled';
import { Backspace, CloudUpload } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';

const VisuallyHiddenInput = ({ onChange }) => (
  <input
    type="file"
    onChange={onChange}
    style={{
      clip: 'rect(0, 0, 0, 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
    }}
  />
);

const FileUploader = ({ label, updateFile, deleteFile }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    updateFile(selectedFile);
  };

  const handleDeleteFile = () => {
    setFile(null);
    deleteFile();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignItems: 'center',
        width: '100%',
        border: '1px dashed',
        borderColor: 'grey.500',
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Button component="label" variant="outlined" startIcon={<CloudUpload />}>
        {label}
        <VisuallyHiddenInput onChange={handleFileChange} />
      </Button>
      {file && (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Button
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={handleDeleteFile}
            endIcon={<Backspace />}
            variant="contained"
            color="error"
          >
            Eliminar
          </Button>
          <img
            src={URL.createObjectURL(file)}
            alt="preview"
            style={{ width: '100%' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FileUploader;
