import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { CustomTable } from 'components/CustomTable';
import moment from 'moment/min/moment-with-locales';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import useStyles from 'styles/styles';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PlaceIcon from '@mui/icons-material/Place';
import { api } from 'common';
import { BookingDetailsModal } from '../components';

const PendingBookings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const { cancelBooking } = api;

  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [pendingBooking, setpendingBooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [cancelBookingModal, setCancelBookingModal] = useState({
    open: false,
    booking: null,
  });
  const options = useSelector(state => state.cancelreasondata.simple);
  const radioGroupRef = useRef(null);
  const [reason, setReason] = useState('');
  const [detailsModal, setDetailsModal] = useState({
    open: false,
    booking: null,
  });

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setpendingBooking(
        bookinglistdata.bookings.filter(booking => booking.status === 'NEW'),
      );
    }
    setLoading(false);
  }, [bookinglistdata]);

  const onConfirmClose = () => {
    dispatch(
      cancelBooking({
        reason: reason,
        booking: cancelBookingModal.booking,
        cancelledBy: auth.profile.uid,
      }),
    );
    setCancelBookingModal({ open: false, booking: null });
  };

  const handleChange = event => {
    setReason(event.target.value);
  };

  const handleRowClick = rowData => {
    setDetailsModal({
      open: true,
      booking: rowData,
    });
  };

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div>
      <CustomTable
        onClickRowAction={handleRowClick}
        data={pendingBooking}
        columns={[
          {
            title: 'Fecha y hora',
            field: 'bookingDate',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData =>
              rowData.bookingDate
                ? moment(rowData.bookingDate).format('DD/MM/YYYY - h:mmA')
                : null,
          },
          {
            title: 'Pasajero',
            field: 'customer_name',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Teléfono pasajero',
            field: 'customer_contact',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData => rowData.customer_contact.replace('+57', ''),
          },
          {
            title: 'Origen',
            field: 'pickupAddress',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Destino',
            field: 'dropAddress',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Costo',
            field: 'trip_cost',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
        ]}
        actions={[
          rowData => ({
            icon: () => (
              <div
                className={classes.action}
                style={{
                  width: '11rem',
                  backgroundColor: '#C82333',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  paddingBlock: '0.5rem',
                  paddingInline: '1rem',
                  borderRadius: '5px',
                }}
              >
                <CancelIcon />
                <Typography variant="subtitle2">
                  {t('cancel_booking')}
                </Typography>
              </div>
            ),
            onClick: (event, rowData) => {
              setCancelBookingModal({
                open: true,
                booking: rowData,
              });
            },
          }),
        ]}
      />

      <Modal
        open={cancelBookingModal.open}
        onClose={() => setCancelBookingModal({ open: false, booking: null })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={'center'}
          >
            Cancelar reserva
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 10,
              marginBlock: 5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                flexGrow: 1,
                flexBasis: '50%',
              }}
            >
              <PlaceIcon sx={{ color: 'green', fontSize: '30px' }} />
              <Typography>
                {cancelBookingModal.booking?.pickupAddress}{' '}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                flexGrow: 1,
                flexBasis: '50%',
              }}
            >
              <SportsScoreIcon sx={{ fontSize: '30px' }} />
              <Typography>
                {cancelBookingModal.booking?.dropAddress}{' '}
              </Typography>
            </Box>
          </Box>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: 'center', fontWeight: 'bold' }}
          >
            Selecciona la razón de la cancelación
          </Typography>

          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={reason}
            onChange={handleChange}
          >
            {options.map(option => (
              <FormControlLabel
                value={option}
                key={option}
                control={<Radio style={{ margin: 10 }} />}
                label={option}
                style={{ padding: 8 }}
              />
            ))}
          </RadioGroup>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 10,
              marginTop: 5,
            }}
          >
            <Button
              color="error"
              onClick={() => {
                onConfirmClose();
              }}
              variant="contained"
            >
              Cancelar reserva
            </Button>
            <Button
              color="success"
              onClick={() =>
                setCancelBookingModal({ open: false, booking: null })
              }
              variant="contained"
            >
              Continuar reserva
            </Button>
          </Box>
        </Box>
      </Modal>

      <BookingDetailsModal
        open={detailsModal.open}
        closeModal={() => setDetailsModal({ open: false, booking: null })}
        booking={detailsModal.booking}
        //onClose={() => setDetailModal({ open: false, booking: null })}
      />
    </div>
  );
};

export default PendingBookings;
