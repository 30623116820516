import { GoogleMap } from '@react-google-maps/api';
import { CustomTable } from 'components/CustomTable';
import moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { BookingDetailsModal } from '../components';

const EndedBookings = () => {
  const { t } = useTranslation();
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [pendingBooking, setpendingBooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailsModal, setDetailsModal] = useState({
    open: false,
    booking: null,
  });

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setpendingBooking(
        bookinglistdata.bookings.filter(booking =>
          ['REACHED', 'COMPLETE'].includes(booking.status),
        ),
      );
    }
    setLoading(false);
  }, [bookinglistdata]);

  const handleRowClick = rowData => {
    setDetailsModal({
      open: true,
      booking: rowData,
    });
  };

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div>
      <CustomTable
        onClickRowAction={handleRowClick}
        data={pendingBooking}
        columns={[
          {
            title: 'Fecha y hora',
            field: 'bookingDate',
            cellStyle: { textAlign: 'center' },
            headerStyle: {
              textAlign: 'center',
            },
            render: rowData =>
              rowData.bookingDate ? (
                <>
                  <p>
                    {moment(rowData.bookingDate).format('DD/MM/YYYY - h:mmA')}
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    {rowData.estimateDistance}km | {rowData.total_trip_time}s
                  </p>
                </>
              ) : null,
          },
          {
            title: 'Conductor',
            field: 'driver_name',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData => (
              <>
                <p>{rowData.driver_name}</p>
                <p>{rowData.driver_contact.replace('+57', '')}</p>
              </>
            ),
          },
          {
            title: 'Placa',
            field: 'vehicle_number',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Pasajero',
            field: 'customer_contact',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
            render: rowData => (
              <>
                <p>{rowData.customer_name}</p>
                <p>{rowData.customer_contact.replace('+57', '')}</p>
              </>
            ),
          },
          {
            title: 'Precio',
            field: 'trip_cost',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Origen',
            field: 'pickupAddress',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
          {
            title: 'Destino',
            field: 'dropAddress',
            cellStyle: { textAlign: 'left' },
            headerStyle: {
              textAlign: 'left',
            },
          },
        ]}
        actions={[]}
      />
      <BookingDetailsModal
        open={detailsModal.open}
        booking={detailsModal.booking}
        closeModal={() => setDetailsModal({ open: false, booking: null })}
        //onClose={() => setDetailModal({ open: false, booking: null })}
      />
    </div>
  );
};

export default EndedBookings;
