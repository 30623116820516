import { Box, Drawer, Grid, SwipeableDrawer } from '@mui/material';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import React, { useContext } from 'react';

const SimpleLayout = ({ children }) => {
  const { openDrawer, content, handleCloseDrawer, handleOpenDrawer } =
    useContext(DrawerContext);

  return (
    <Grid
      container
      spacing={4}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        padding: 5,
        width: '100%',
        position: 'relative',
        //maxWidth: 'calc(100vw - 330px) !important',
      }}
    >
      <Grid item xs={12}>
        {children}
      </Grid>

      <SwipeableDrawer
        anchor={'right'}
        open={openDrawer}
        onClose={handleCloseDrawer}
        onOpen={handleOpenDrawer}
        sx={{
          '& .MuiDrawer-paper': { minWidth: '40vw', maxWidth: '60vw' },
        }}
      >
        <Box
          sx={{
            marginTop: 10,
            padding: 5,
          }}
        >
          {content}
        </Box>
      </SwipeableDrawer>
    </Grid>
  );
};

export default SimpleLayout;
