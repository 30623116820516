import {
  Button,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  Switch,
  Typography,
  Divider,
} from '@mui/material';
import { LabeledInput } from 'components/Molucules';
import { useEffect, useState } from 'react';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import { CustomAutoComplete } from 'components/Molucules';
import { Roles } from 'models';
import GoogleMapsAutoComplete from 'components/GoogleMapsAutoComplete';
import { useAddBooking } from './hooks';
import { LabeledText } from 'components/Molucules';
import { GoogleMapApiConfig } from 'config/GoogleMapApiConfig';
import GoogleMapReact from 'google-map-react';
import { CustomMapMark } from 'views/BookingHistory';

const AddBookingPage = () => {
  const {
    confirmBooking,
    estimateRouteDetails,
    t,
    loadCustomers,
    loadDrivers,
    updateSelectedCustomer,
    updateCustomerName,
    updatePickupAddress,
    updateDropAddress,
    updateCustomerPhone,
    setEstimateModalStatus,
    loadCurrentUser,
    updateCarDetails,
    estimateModalStatus,
    loading,
    customers,
    rideData,
    auth,
    usersData,
    estimateData,
    settings,
    name,
    cartypes,
  } = useAddBooking();

  const [mapProps, setMapProps] = useState({
    center: {
      lat: 7.89391,
      lng: -72.50782,
    },
    setNewCoords: null,
  });

  const [aditionalOptions, setAditionalOptions] = useState([
    {
      name: 'Baúl grande',
      value: false,
    },
    {
      name: 'Aire acondicionado',
      value: false,
    },
    {
      name: 'Mascotas',
      value: false,
    },
    {
      name: 'Silla de ruedas',
      value: false,
    },
    {
      name: 'Parrilla',
      value: false,
    },
    {
      name: 'Transferencia',
      value: false,
    },
  ]);
  const options = [
    'Baúl grande',
    'Aire acondicionado',
    'Mascotas',
    'Silla de ruedas',
    'Parrilla',
    'Transferencia',
  ];

  const handleAditionalOptions = (event, option) => {
    setAditionalOptions(
      aditionalOptions.map(item =>
        item.name === option ? { ...item, value: event.target.checked } : item,
      ),
    );
  };

  const getAdditionalOptions = () => {
    const options = aditionalOptions.filter(option => option.value === true);
    let message = '';
    options.forEach(option => {
      message += option.name + ' - ';
    });
    return message;
  };

  const calculateMapCenter = () => {
    if (!rideData.pickupAddress && !rideData.dropAddress) {
      return {
        lat: 7.89391,
        lng: -72.50782,
      };
    }

    if (rideData.pickupAddress && !rideData.dropAddress) {
      const lat = rideData.pickupAddress.coords.lat;
      const lng = rideData.pickupAddress.coords.lng;
      return {
        lat,
        lng,
      };
    }
    if (!rideData.pickupAddress && rideData.dropAddress) {
      const lat = rideData.dropAddress.coords.lat;
      const lng = rideData.dropAddress.coords.lng;
      return {
        lat,
        lng,
      };
    }
    const lat =
      (rideData.pickupAddress.coords.lat + rideData.dropAddress.coords.lat) / 2;
    const lng =
      (rideData.pickupAddress.coords.lng + rideData.dropAddress.coords.lng) / 2;
    return {
      lat,
      lng,
    };
  };

  useEffect(() => {
    loadCustomers();
    loadDrivers();
    loadCurrentUser();
  }, [usersData]);

  useEffect(() => {
    setMapProps({
      center: calculateMapCenter(),
    });
  }, [rideData]);

  useEffect(() => {
    updateCarDetails(
      auth.profile.city === 'Bogotá' ? cartypes[1] : cartypes[0],
    );
  }, [auth]);

  if (loading) {
    return <div>CARGANDO...</div>;
  }

  return (
    <div>
      <Grid container spacing={2} sx={{ padding: '0 !important' }}>
        <Grid item xs={4} sx={{ padding: '0 !important' }}>
          {customers && auth.profile.usertype !== Roles.CUSTOMER && (
            <CustomAutoComplete
              label={'Teléfono del pasajero'}
              placeholder={'Teléfono'}
              value={rideData.customer ? rideData.customer.mobile : ''}
              updateValue={user => {
                updateSelectedCustomer(user);
              }}
              updateInputValue={text => {
                updateCustomerPhone(text);
              }}
              icon={<PhoneAndroidIcon />}
              options={customers}
            />
          )}
          <div style={{ width: 1, height: 30 }} />
          {auth.profile.usertype !== Roles.CUSTOMER &&
          rideData.customer.firstName ? (
            <LabeledText
              label={'Nombre del pasajero'}
              icon={<PersonIcon />}
              text={`${rideData.customer.firstName} ${rideData.customer.lastName}`}
            />
          ) : (
            <LabeledInput
              value={name}
              label={'Nombre del pasajero'}
              placeholder={'Nombre'}
              updateText={updateCustomerName}
              icon={<PersonIcon />}
              //props={{ disabled: false }}
            />
          )}
          <div style={{ width: 1, height: 30 }} />
          <GoogleMapsAutoComplete
            placeholder={'Dirección de recogida'}
            value={rideData.pickupAddress}
            onChange={address => {
              updatePickupAddress(address);
            }}
            icon={<PersonPinCircleIcon />}
            externalValue={rideData.pickupAddress?.add}
          />
          <div style={{ width: 1, height: 30 }} />
          <GoogleMapsAutoComplete
            placeholder={'Dirección de destino'}
            value={rideData.dropAddress}
            onChange={address => {
              updateDropAddress(address);
            }}
            icon={<SportsScoreIcon />}
            externalValue={rideData.dropAddress?.add}
          />
          <div style={{ width: 1, height: 10 }} />
          <FormGroup>
            <FormLabel id="demo-radio-buttons-group-label">
              Opciones adicionales de reserva
            </FormLabel>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {options.map(option => (
                <FormControlLabel
                  control={
                    <Switch onChange={e => handleAditionalOptions(e, option)} />
                  }
                  label={option}
                />
              ))}
            </div>
          </FormGroup>
        </Grid>
        <Grid item xs={8}>
          <div style={{ height: '80%', minWidth: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GoogleMapApiConfig }}
              //defaultCenter={{ ...mapProps.center }}
              defaultZoom={13}
              center={{ ...mapProps.center }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                const markers = [];
                const infowindows = [];
              }}
              onClick={e => {
                if (!mapProps.setNewCoords) {
                  setMapProps({
                    center: {
                      lat: e.lat,
                      lng: e.lng,
                    },
                    setNewCoords: {
                      lat: e.lat,
                      lng: e.lng,
                    },
                  });
                }
              }}
            >
              {rideData.pickupAddress &&
                CustomMapMark.createLocationMarket({
                  lat: rideData.pickupAddress.coords.lat,
                  lng: rideData.pickupAddress.coords.lng,
                  title: 'A' /* road circle */,
                })}

              {rideData.dropAddress &&
                CustomMapMark.createLocationMarket({
                  lat: rideData.dropAddress.coords.lat,
                  lng: rideData.dropAddress.coords.lng,
                  title: 'B' /* road circle */,
                })}

              {mapProps.setNewCoords &&
                CustomMapMark.createSelectPlaceMarket({
                  coords: {
                    lat: mapProps.setNewCoords.lat,
                    lng: mapProps.setNewCoords.lng,
                  },
                  title: 'C' /* road circle */,
                  setOrigin: coords => {
                    updatePickupAddress(coords);
                  },
                  setDestination: coords => {
                    updateDropAddress(coords);
                  },
                  setNewCoords: () => {
                    setMapProps({
                      center: {
                        lat: 7.89391,
                        lng: -72.50782,
                      },
                      setNewCoords: null,
                    });
                  },
                })}
            </GoogleMapReact>
          </div>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#69C33B',
              '&:hover': {
                backgroundColor: '#52992E',
              },
              height: '4rem',
              marginTop: 1,
              width: '100%',
            }}
            startIcon={<LocalTaxiIcon />}
            onClick={estimateRouteDetails}
          >
            RESERVAR
          </Button>
        </Grid>
      </Grid>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            width: 480,
            backgroundColor: '#fff',
            paddingBlock: 5,
            paddingInline: 5,
            borderRadius: 5,
            boxShadow: 24,
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              color={'primary'}
              style={{ fontSize: 30, textAlign: 'center' }}
            >
              {t('total')} -{' '}
              {estimateData.estimate
                ? estimateData.estimate.estimateFare
                : null}
              {settings ? settings.symbol : null}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {loading ? (
              <DialogActions
                style={{ justifyContent: 'center', alignContent: 'center' }}
              >
                <CircularProgress />
              </DialogActions>
            ) : (
              <Grid
                item
                xs={12}
                display={'flex'}
                gap={4}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Button
                  onClick={() => {
                    setEstimateModalStatus(false);
                  }}
                  variant="contained"
                  color="error"
                >
                  {t('cancel')}
                </Button>
                <Button
                  onClick={e => confirmBooking(e, getAdditionalOptions())}
                  variant="contained"
                  color="primary"
                >
                  {t('book_now')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default AddBookingPage;
