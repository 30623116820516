import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  FETCH_ALL_USERS_STATIC,
  FETCH_ALL_USERS_STATIC_SUCCESS,
  FETCH_ALL_USERS_STATIC_FAILED,
  USER_DELETED,
} from '../store/types';
import { firebase } from '../config/configureFirebase';
import {
  onValue,
  set,
  push,
  update,
  off,
  get,
  remove,
} from 'firebase/database';
import { getDownloadURL, uploadBytes } from 'firebase/storage';
import { signOut } from 'firebase/auth';

export const fetchUsers = () => dispatch => {
  const { usersRef, allLocationsRef } = firebase;

  dispatch({
    type: FETCH_ALL_USERS,
    payload: null,
  });
  onValue(usersRef, async snapshot => {
    if (snapshot.val()) {
      const locationdata = await get(allLocationsRef);
      const locations = locationdata.val();
      const data = snapshot.val();
      const arr = Object.keys(data)
        .filter(i => data[i].usertype != 'admin')
        .map(i => {
          data[i].id = i;
          data[i].location = locations && locations[i] ? locations[i] : null;
          return data[i];
        });
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_ALL_USERS_FAILED,
        payload: 'No users available.',
      });
    }
  });
};

export const fetchUsersOnce = () => dispatch => {
  const { usersRef, allLocationsRef } = firebase;

  dispatch({
    type: FETCH_ALL_USERS_STATIC,
    payload: null,
  });
  onValue(
    usersRef,
    async snapshot => {
      if (snapshot.val()) {
        const locationdata = await get(allLocationsRef);
        const locations = locationdata.val();
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          data[i].location = locations && locations[i] ? locations[i] : null;
          return data[i];
        });
        dispatch({
          type: FETCH_ALL_USERS_STATIC_SUCCESS,
          payload: arr,
        });
      } else {
        dispatch({
          type: FETCH_ALL_USERS_STATIC_FAILED,
          payload: 'No users available.',
        });
      }
    },
    { onlyOnce: true },
  );
};

export const fetchDrivers = () => async dispatch => {
  const { driversRef, allLocationsRef, settingsRef } = firebase;

  const settingsdata = await get(settingsRef);
  const settings = settingsdata.val();

  dispatch({
    type: FETCH_ALL_USERS,
    payload: null,
  });

  onValue(
    driversRef,
    snapshot => {
      if (snapshot.val()) {
        onValue(
          allLocationsRef,
          locres => {
            const locations = locres.val();
            const data = snapshot.val();
            const arr = Object.keys(data)
              .filter(
                i =>
                  data &&
                  data[i].usertype == 'driver' &&
                  data[i].approved == true &&
                  data[i].driverActiveStatus == true &&
                  locations &&
                  locations[i] &&
                  ((data[i].licenseImage && settings.license_image_required) ||
                    !settings.license_image_required) &&
                  ((data[i].carApproved && settings.carType_required) ||
                    !settings.carType_required ||
                    !settings.carType_required) &&
                  ((data[i].term && settings.term_required) ||
                    !settings.term_required),
              )
              .map(i => {
                return {
                  id: i,
                  location: locations && locations[i] ? locations[i] : null,
                  carType: data[i].carType ? data[i].carType : null,
                };
              });
            dispatch({
              type: FETCH_ALL_USERS_SUCCESS,
              payload: arr,
            });
          },
          { onlyOnce: true },
        );
      } else {
        dispatch({
          type: FETCH_ALL_USERS_FAILED,
          payload: 'No users available.',
        });
      }
    },
    { onlyOnce: true },
  );
};

export const addUser = userdata => dispatch => {
  const { usersRef } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: userdata,
  });

  delete userdata.tableData;

  push(usersRef, userdata)
    .then(() => {
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: null,
      });
    })
    .catch(error => {
      dispatch({
        type: EDIT_USER_FAILED,
        payload: error,
      });
    });
};

export const addUserAndReturnUid = userdata => dispatch => {
  const { usersRef } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: userdata,
  });

  delete userdata.tableData;

  return new Promise((resolve, reject) => {
    push(usersRef, userdata)
      .then(ref => {
        dispatch({
          type: EDIT_USER_SUCCESS,
          payload: null,
        });
        resolve(ref.key);
      })
      .catch(error => {
        dispatch({
          type: EDIT_USER_FAILED,
          payload: error,
        });
        reject(error);
      });
  });
};

export const editUser = (id, user) => dispatch => {
  const { singleUserRef } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: user,
  });
  let editedUser = user;
  delete editedUser.id;
  delete editedUser.tableData;
  set(singleUserRef(id), editedUser);
};

export const updateUserCar = (id, data) => dispatch => {
  const { singleUserRef } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: data,
  });
  update(singleUserRef(id), data);
};

export const updateLicenseImage =
  (uid, imageBlob, imageType) => async dispatch => {
    const {
      singleUserRef,
      driverDocsRef,
      driverDocsRefBack,
      verifyIdImageRef,
    } = firebase;

    let profile = {};
    if (imageType === 'licenseImage') {
      await uploadBytes(driverDocsRef(uid), imageBlob);
      let image = await getDownloadURL(driverDocsRef(uid));
      profile.licenseImage = image;
    }
    if (imageType === 'licenseImageBack') {
      await uploadBytes(driverDocsRefBack(uid), imageBlob);
      let image1 = await getDownloadURL(driverDocsRefBack(uid));
      profile.licenseImageBack = image1;
    }
    if (imageType === 'verifyIdImage') {
      await uploadBytes(verifyIdImageRef(uid), imageBlob);
      let image1 = await getDownloadURL(verifyIdImageRef(uid));
      profile.verifyIdImage = image1;
    }
    update(singleUserRef(uid), profile);
    dispatch({
      type: EDIT_USER,
      payload: uid,
    });
  };

export const UploadImage = async (imgRef, imgBlob, uid) => {
  try {
    const response = await uploadBytes(imgRef(uid), imgBlob);
    const imageSource = await getDownloadURL(imgRef(uid));
    return imageSource;
  } catch (err) {
    console.error(err);
  }
};

export const updateProfileUserImage = (uid, imageBlob) => async dispatch => {
  const { singleUserRef, profileImageRef } = firebase;

  let profile = {};
  const image = await UploadImage(profileImageRef, imageBlob, uid);
  profile.profile_image = image;

  update(singleUserRef(uid), profile);
  dispatch({
    type: EDIT_USER,
    payload: uid,
  });
};

export const updateUserDocumentation = (uid, data) => async dispatch => {
  const {
    singleUserRef,
    driverDocsRef, // license image front
    driverDocsRefBack, // license image back
    driverOperatingCardRef,
    driverOwnershipCardRef,
    driverTarjetonRef,
    driverOwnershipCardBackRef,
    driverOcassionalTripDocument,
  } = firebase;

  let profile = {};

  try {
    switch (data.id) {
      case 1:
        profile.licenseImage = await UploadImage(
          driverDocsRef,
          data.imageBlob,
          uid,
        );
        break;

      case 2:
        profile.licenseImageBack = await UploadImage(
          driverDocsRefBack,
          data.imageBlob,
          uid,
        );
        break;

      case 3:
        profile.operatingCard = await UploadImage(
          driverOperatingCardRef,
          data.imageBlob,
          uid,
        );
        break;

      case 4:
        profile.ownershipCard = await UploadImage(
          driverOwnershipCardRef,
          data.imageBlob,
          uid,
        );
        break;

      case 5:
        profile.ownershipCardBack = await UploadImage(
          driverOwnershipCardBackRef,
          data.imageBlob,
          uid,
        );
        break;

      case 6:
        profile.tarjeton = await UploadImage(
          driverTarjetonRef,
          data.imageBlob,
          uid,
        );
        break;

      case 7:
        if (data.imageBlob) {
          const imgUrl = await UploadImage(
            driverOcassionalTripDocument,
            data.imageBlob,
            uid,
          );
          profile.ocassionalTripDocument = {
            img: imgUrl,
            expirationDate: data.expirationDate,
          };
        } else {
          profile.ocassionalTripDocument = null;
        }
        break;

      case 8:
        updateProfileUserImage(uid, data.imageBlob);
        break;

      default:
        break;
    }

    update(singleUserRef(uid), profile);
    dispatch({
      type: EDIT_USER,
      payload: uid,
    });
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteUser = uid => dispatch => {
  const {
    auth,
    walletHistoryRef,
    singleUserRef,
    userNotificationsRef,
    carsRef,
    carEditRef,
  } = firebase;

  dispatch({
    type: DELETE_USER,
    payload: uid,
  });

  if (auth.currentUser.uid === uid) {
    off(singleUserRef(uid));
    off(walletHistoryRef(uid));
    off(userNotificationsRef(uid));
  }

  onValue(
    singleUserRef(uid),
    userdata => {
      const profile = userdata.val();
      if (profile.usertype === 'driver') {
        onValue(carsRef(uid, profile.usertype), carssnapshot => {
          let cars = carssnapshot.val();
          if (cars) {
            const arr = Object.keys(cars);
            for (let i = 0; i < arr.length; i++) {
              remove(carEditRef(arr[i]));
            }
          }
        });
      }

      remove(singleUserRef(uid)).then(() => {
        if (auth.currentUser.uid === uid) {
          signOut(auth);
          dispatch({
            type: USER_DELETED,
            payload: null,
          });
        } else {
          remove(singleUserRef(uid))
            .then(() => {
              dispatch({
                type: DELETE_USER_SUCCESS,
                payload: null,
              });
            })
            .catch(error => {
              dispatch({
                type: DELETE_USER_FAILED,
                payload: error,
              });
            });
        }
      });
    },
    { onlyOnce: true },
  );
};

export const getUserByPhone = async phoneNumber => {
  const { usersRef } = firebase;

  const snapshot = await get(usersRef);
  const data = snapshot.val();
  const arr = Object.keys(data).map(i => {
    data[i].id = i;
    return data[i];
  });
  return arr.find(user => user.mobile === phoneNumber);
};

export const getUserById = async userId => {
  const { singleUserRef } = firebase;

  const snapshot = await get(singleUserRef(userId));
  return snapshot.val();
};

export const ratePassenger = async (
  bookingUid,
  passengerUid,
  passengerRate,
  driverUid,
  comments = '',
) => {
  const { passengerRatingRef } = firebase;

  try {
    const snapshot = await get(passengerRatingRef(passengerUid));

    if (!snapshot.exists()) {
      createPassengerRating(bookingUid, passengerUid, passengerRate, driverUid);
      return;
    }

    const passengerRatingData = snapshot.val();
    const newRating = (passengerRatingData.totalRate + passengerRate) / 2;

    const updatedRatingData = {
      totalRate: newRating,
      ratingHistory: [
        ...passengerRatingData.ratingHistory,
        {
          bookingUid: bookingUid,
          rateByDriverUid: driverUid,
          rating: 5,
          comments: comments,
          passengerUid: passengerUid,
        },
      ],
    };

    const response = await update(
      passengerRatingRef(passengerUid),
      updatedRatingData,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getPassengerRate = async passengerUid => {
  const { passengerRatingRef } = firebase;

  const snapshot = await get(passengerRatingRef(passengerUid));

  return snapshot.val();
};

export const createPassengerRating = (
  bookingUid,
  passengerUid,
  passengerRate,
  driverUid,
) => {
  const { passengerRatingRef } = firebase;

  const defaultRatingData = {
    totalRate: passengerRate,
    ratingHistory: [
      {
        bookingUid: bookingUid,
        rateByDriverUid: driverUid,
        rating: 5,
        comments: '',
        passengerUid: passengerUid,
      },
    ],
  };
  set(passengerRatingRef(passengerUid), defaultRatingData);
};

export const updateUserApproval = async (uid, approved) => {
  const { singleUserRef } = firebase;

  const response = await update(singleUserRef(uid), { approved: approved });

  return response;
};

export const updateUserServiceStatus = async (uid, status) => {
  const { singleUserRef } = firebase;

  const response = await update(singleUserRef(uid), {
    queue: status,
  });

  return response;
};
