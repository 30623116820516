import { Box } from '@mui/material';
import React from 'react';
import CreateAdmin from '../CreateAdmin';

export const AdminPage = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <CreateAdmin />
      </Box>
    </Box>
  );
};
