import { create } from '@mui/material/styles/createTransitions';
import { api } from 'common';
import { validateBookingObj } from 'common/sharedFunctions';
import { Roles } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useAddBooking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    getEstimate,
    GetDistance,
    addBooking,
    addUserAndReturnUid,
    fetchUsersOnce,
  } = api;

  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const usersData = useSelector(state => state.usersdata);
  const estimateData = useSelector(state => state.estimatedata);
  const cartypes = useSelector(state => state.cartypes.cars);

  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [errors, setErrors] = useState([]);

  const [tempRoute, setTempRoute] = useState();
  const [name, setName] = useState('');
  const [rideData, setRideData] = useState({
    pickupAddress: null,
    dropAddress: null,
    customer: {
      firstName: null,
      mobile: '',
    },
    driver: null,
    status: null,
    date: null,
    price: null,
    carDetails: cartypes[0],
    instructionData: {
      deliveryPerson: '',
      deliveryPersonPhone: '',
      pickUpInstructions: '',
      deliveryInstructions: '',
      parcelTypeIndex: 0,
      optionIndex: 0,
      parcelTypeSelected: null,
      optionSelected: null,
    },
  });

  const loadCurrentUser = () => {
    if (auth.profile.usertype === Roles.CUSTOMER) {
      setRideData({
        ...rideData,
        customer: {
          firstName: auth.profile.firstName,
          lastName: auth.profile.lastName ? auth.profile.lastName : '',
          uid: auth.profile.uid,
          mobile: auth.profile.mobile ? auth.profile.mobile : '',
          email: auth.profile.email ? auth.profile.email : '',
          pushToken: auth.profile.pushToken ? auth.profile.pushToken : null,
        },
      });
    }
  };

  const loadCustomers = () => {
    setLoading(true);
    if (usersData.users) {
      const customers = usersData.users
        .filter(
          user =>
            user.usertype !== Roles.DRIVER &&
            user.mobile !== '' &&
            user.firstName !== '',
        )
        .map(customer => {
          return {
            firstName: customer.firstName,
            lastName: customer.lastName,
            mobile: customer.mobile,
            email: customer.email,
            uid: customer.id,
            description: `${customer.firstName} ${customer.lastName} - ${String(
              customer.mobile,
            ).replace('+57', '')}`,
            pushToken: customer.pushToken,
          };
        });
      setCustomers(customers);
    }
    setLoading(false);
  };

  const loadDrivers = () => {
    setLoading(true);
    if (usersData.users) {
      const drivers = usersData.users
        .filter(user => user.usertype === Roles.DRIVER)
        .map(driver => {
          return {
            uid: driver.id,
            location: driver.location,
            carType: 'taxi',
            fleetadmin: driver.fleetadmin ? driver.fleetadmin : null,
          };
        });
      setDrivers(drivers);
    }
    setLoading(false);
  };

  const updateSelectedCustomer = newCustomer => {
    if (!newCustomer) {
      setName('');
      setRideData({
        ...rideData,
        customer: {
          firstName: '',
          mobile: '',
        },
      });
      return;
    }

    setRideData({
      ...rideData,
      customer: newCustomer,
    });
    setName(newCustomer.firstName);
  };

  const updateCustomerName = name => {
    setName(name);
  };

  const updatePickupAddress = address => {
    setRideData({ ...rideData, pickupAddress: address });
  };

  const updateDropAddress = address => {
    setRideData({ ...rideData, dropAddress: address });
  };

  const updateCustomerPhone = phone => {
    if (!phone) {
      setRideData({
        ...rideData,
        customer: {
          firstName: '',
          mobile: '',
        },
      });
      return;
    }
    setRideData({
      ...rideData,
      customer: {
        firstName: '',
        mobile: phone,
      },
    });
  };

  const updateCarDetails = carDetails => {
    setRideData({ ...rideData, carDetails });
  };

  const updateInstructions = instructionData => {
    setRideData({ ...rideData, instructionData });
  };

  const estimateRouteDetails = e => {
    e.preventDefault();
    if (validateBookingData() === false) {
      alert(
        `[ERROR] Por favor verifique los siguientes datos obligatorios \n ${errors.join(
          '\n',
        )}`,
      );
      return;
    }

    const { pickupAddress, dropAddress } = rideData;

    const directionService = new window.google.maps.DirectionsService();

    directionService.route(
      {
        origin: new window.google.maps.LatLng(
          pickupAddress.coords.lat,
          pickupAddress.coords.lng,
        ),
        destination: new window.google.maps.LatLng(
          dropAddress.coords.lat,
          dropAddress.coords.lng,
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const route = {
            distance_in_km: result.routes[0].legs[0].distance.value / 1000,
            time_in_secs: result.routes[0].legs[0].duration.value,
            polylinePoints: result.routes[0].overview_polyline,
          };
          setTempRoute(route);

          let estimateRequest = {
            pickup: pickupAddress,
            drop: dropAddress,
            carDetails: rideData.carDetails,
            instructionData: rideData.instructionData,
            routeDetails: route,
          };

          dispatch(getEstimate(estimateRequest));
          setEstimateModalStatus(true);
        }
      },
    );
    //}
  };

  const estimateTripData = e => {
    e.preventDefault();
    //setOptionModalStatus(false);
    let estimateRequest = {
      pickup: rideData.pickupAddress,
      drop: rideData.dropAddress,
      carDetails: rideData.carDetails,
      instructionData: rideData.instructionData,
      routeDetails: tempRoute,
    };
    dispatch(getEstimate(estimateRequest));
  };

  const confirmBooking = async (e, additionalComments = '') => {
    e.preventDefault();

    const user = await validUser();
    if (!user) {
      return;
    }

    let availableDrivers = drivers.filter(driver => driver.location !== null);
    availableDrivers = availableDrivers.filter(driver => {
      const distance = GetDistance(
        rideData.pickupAddress.coords.lat,
        rideData.pickupAddress.coords.lng,
        driver.location.lat,
        driver.location.lng,
      );
      driver['distance'] = distance;
      return settings.driverRadius >= distance;
    });

    if (availableDrivers.length === 0) {
      alert(t('no_driver_found_alert_messege'));
      return;
    }

    let driverEstimates = {};

    for (let driver in drivers) {
      driverEstimates[driver['uid']] = {
        distance: driver['distance'],
        timein_text: (driver['distance'] * 2 + 1).toFixed(0) + ' min',
      };
    }

    const adaptedDriversUidData = {};

    availableDrivers.forEach(driver => {
      adaptedDriversUidData[driver.uid] = true;
    });

    let bookingObject = {
      pickup: rideData.pickupAddress,
      drop: rideData.dropAddress,
      carDetails: rideData.carDetails,
      userDetails: user,
      estimate: estimateData.estimate,
      instructionData: rideData.instructionData,
      tripInstructions: '',
      roundTrip: false,
      tripdate: new Date().getTime(),
      bookLater: false,
      settings: settings,
      booking_type_admin: [Roles.ADMIN, Roles.MANAGER].includes(
        auth.profile.usertype,
      )
        ? true
        : false,
      fleetadmin:
        auth.profile.usertype === Roles.FLEET_ADMIN ? auth.profile.uid : null,
      cabCompany:
        auth.profile.usertype === Roles.CAB_COMPANY ? auth.profile.uid : null,
      requestedDrivers: adaptedDriversUidData,
      driverEstimates: driverEstimates,
      payment_mode: 'cash',
      booking_from_web: true,
    };

    setLoading(true);

    const result = validateBookingObj(
      t,
      bookingObject,
      rideData.instructionData,
    );
    if (result.error) {
      setLoading(false);
      alert('[ERROR] Por favor verifique los datos de la reserva');
    } else {
      let bookingData = result.bookingObject;
      bookingData.userDetails.firstName = user.firstName;
      bookingData.userDetails.lastName = user.lastName;
      bookingData.userDetails.email = user.email ? user.email : '';
      bookingData.comments = additionalComments;

      dispatch(addBooking(bookingData));
      setEstimateModalStatus(true);
      setLoading(false);
      navigate('/bookings', { replace: true });
    }
  };

  const validateBookingData = () => {
    const { pickupAddress, dropAddress, customer } = rideData;
    const errors = [];

    if (!pickupAddress) {
      errors.push('pickupAddress');
    }
    if (!dropAddress) {
      errors.push('dropAddress');
    }
    if (!customer.mobile) {
      errors.push('mobile');
    }
    if (!customer.firstName && name === '') {
      errors.push('firstName');
    }

    setErrors(errors);
    return errors.length === 0;
  };

  const validUser = async () => {
    if (!rideData.customer.uid) {
      const user = await createNewBasicUser();
      return user;
    }
    return rideData.customer;
  };

  const createNewBasicUser = async () => {
    const user = {
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1] ? name.split(' ')[1] : '',
      mobile: rideData.customer.mobile,
      rideHistory: {
        lastOrigin: rideData.pickupAddress.addressName,
        lastDestination: rideData.dropAddress.addressName,
      },
      createdAt: new Date().getTime(),
      usertype: Roles.CUSTOMER,
      webFlag: true,
    };

    try {
      // Llama a la función addUser y espera a que se complete
      const userUid = await dispatch(addUserAndReturnUid(user));

      user['uid'] = userUid;
      setRideData({
        ...rideData,
        customer: { ...user },
      });

      // Actualiza la lista de usuarios para incluir el nuevo usuario
      await dispatch(fetchUsersOnce());

      return user;
    } catch (err) {
      alert(
        err.msg +
          '\n[ERROR] Hubo un error intentando realizar la reserva, verifique los datos e intente nuevamente',
      );
    }
  };

  return {
    confirmBooking,
    estimateTripData,
    estimateRouteDetails,
    t,
    loadCustomers,
    loadDrivers,
    loadCurrentUser,
    updateSelectedCustomer,
    updateCustomerName,
    updatePickupAddress,
    updateDropAddress,
    updateCarDetails,
    updateInstructions,
    updateCustomerPhone,
    setEstimateModalStatus,
    estimateModalStatus,
    loading,
    customers,
    state,
    rideData,
    auth,
    usersData,
    estimateData,
    settings,
    cartypes,
    name,
  };
};

export default useAddBooking;
