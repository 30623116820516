const Roles = {
  CUSTOMER: 'customer',
  ADMIN: 'admin',
  DRIVER: 'driver',
  FLEET_ADMIN: 'fleetadmin',
  CAB_COMPANY: 'cabCompany',
  MANAGER: 'manager',
};

export default Roles;
