import { Box, Button, Icon, Typography } from '@mui/material';
import {
  greatPlaceStyle,
  infoWindowStyle,
  taxiPlaceStyle,
} from './MarketStyles';
import { useState } from 'react';
import MyLocationIcon from '@mui/icons-material/MyLocation';

const MarketExtraInfo = data => {
  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>{data.title}</div>
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>{data.rating} </span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(data.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(data.rating))}
        </span>
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>{data.types[0]}</div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(data.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {data.opening_hours.open_now ? 'Open' : 'Closed'}
      </div>
    </div>
  );
};

const LocationMarker = ({ title, lat, lng, placeAddressName }) => {
  return <div style={greatPlaceStyle}>{title}</div>;
};

const TaxiMarker = ({ title, lat, lng, driverData, taxiStatus = 'free' }) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const onMarkerClick = () => {
    setShowExtraInfo(!showExtraInfo);
  };

  return (
    <Button style={{}} onClick={onMarkerClick}>
      {taxiStatus === 'bussy' ? (
        <img
          src="https://img.recraft.ai/0E88jcPt-6RaDfkM18Cu7BYMGyiBP0da1J9C4L0TJuI/rs:fit:1024:1024:0/q:80/g:no/plain/abs://prod/images/b443fc34-2dc5-453d-9d8b-cee158e91fb6@avif"
          alt="taxi"
          srcSet=""
          style={{ ...taxiPlaceStyle }}
        />
      ) : (
        <img
          src="https://th.bing.com/th/id/R.edeba5e177ef2b03a7cbf1990018fb12?rik=PallBDqlfvBAxQ&pid=ImgRaw&r=0"
          alt="taxi"
          srcSet=""
          style={{ ...taxiPlaceStyle }}
        />
      )}

      {showExtraInfo && (
        <div style={infoWindowStyle}>
          <div style={{ fontSize: 14 }}> Taxista </div>
          <div
            style={{ fontSize: 12, color: '#000', textTransform: 'capitalize' }}
          >
            {`${driverData.firstName} ${driverData.lastName}`}
          </div>
          <div style={{ fontSize: 12, color: '#000' }}>
            {driverData.mobile.replace('+57', '')}
          </div>

          <div style={{ fontSize: 10, color: 'grey' }}>
            {driverData.arriveTime.timein_text} | {'\t'}
            {Number(driverData.distance).toFixed(2)} km
          </div>
        </div>
      )}
    </Button>
  );
};

const SelectPlaceMarker = ({
  title,
  lat,
  lng,
  setOrigin,
  setDestination,
  coords,
  setNewCoords,
}) => {
  const fetchAddressData = async () => {
    const urlRequest = `https://maps.googleapis.com/maps/api/geocode/json?address=${coords.lat},${coords.lng}`;
    const additionalOptions = `&key=AIzaSyCWTd8Bk9enX-j4lzSPWBDRQGim3XLGF2s`;
    try {
      const response = await fetch(urlRequest + additionalOptions);
      const data = await response.json();

      if (data.results.length > 0) {
        return {
          addressName: data.results[0].formatted_address,
          add: data.results[0].formatted_address,
          description: data.results[0].formatted_address,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onOriginClick = async () => {
    const data = await fetchAddressData();
    setOrigin({
      coords,
      addressName: data.addressName,
      description: data.description,
      add: data.add,
    });
  };

  const onDestinationClick = async () => {
    const data = await fetchAddressData();
    setDestination({
      coords,
      addressName: data.addressName,
      description: data.description,
      add: data.add,
    });
  };

  const onCancelClick = () => {
    setNewCoords(null);
  };

  return (
    <Box
      sx={{ backgroundColor: 'red', height: 2, width: 2, position: 'relative' }}
    >
      <MyLocationIcon fontSize="small" sx={{ color: 'blue' }} />

      <div
        style={{
          position: 'absolute',
          bottom: 0,
          minWidth: 150,
          backgroundColor: 'white',
          boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
          padding: 10,
          fontSize: 14,
          zIndex: 100,
          borderRadius: 10,
          pointerEvents: 'none',
        }}
      >
        <Button style={{ pointerEvents: 'auto' }} onClick={onOriginClick}>
          <Typography>Recogida</Typography>
        </Button>

        <Button style={{ pointerEvents: 'auto' }} onClick={onDestinationClick}>
          <Typography>Destino</Typography>
        </Button>

        <Button style={{ pointerEvents: 'auto' }} onClick={onCancelClick}>
          <Typography>Cancelar</Typography>
        </Button>
      </div>
    </Box>
  );
};

class CustomMapMark {
  static createTaxiMarket(params) {
    return <TaxiMarker {...params} />;
  }

  static createLocationMarket(params) {
    return <LocationMarker {...params} />;
  }

  static createSelectPlaceMarket(params) {
    return <SelectPlaceMarker {...params} />;
  }
}

export default CustomMapMark;
